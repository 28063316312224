@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");

.FormContainer {
  font-family: "Prompt", sans-serif;
  text-align: center;
}

.MuiToolbar-root .MuiToolbar-gutters .MuiToolbar-regular .css-hyum1k-MuiToolbar-root {
  display: none;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

button {
  border: none;
  padding: 8px;
  margin-right: 2px;
}

.blue {
  background-color: #0e1937;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
}

.green {
  background-color: darkolivegreen;
  color: white;
}

.btn-toolbar {
  padding-bottom: 10px;
  /* margin: auto; */
}

.halfw {
  width: 50%;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0e1937;
  border-color: wite;
}

.btn-primary {
  color: #fff;
  background-color: #0e1937;
  border-color: #172130;
}

.formio-builder-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.form-wrapper .mdb-label {
  transform: translateY(-1.6rem);
  font-size: 0.8rem;
}

.form-group {
  width: 80%;
  margin-left: 11%;
}

.builder-component {
  width: 50%;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h2 {
  margin-top: 0;
}

.card p {
  margin-bottom: 0;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal>div {
  float: right;
  margin: 10px;
  padding: 20px;
  width: 80%;
  background-color: white;
  border-radius: 5px;
}

.modal .close {
  color: #000000;
  float: right;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
  text-align: right;
}

.modal .close:hover {
  color: #000;
}

.table {
  width: 150% !important;
}

.table tbody tr td path {
  display: none;
}

.table tbody tr {
  width: 500px;
}

.formNameInput {
  width: 300px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 8px;
  margin: 0px 20px;
}

.right {
  text-align: right;
}

.coopListBtn {
  margin: 1;
  border-radius: 5px;
}

.coopNumberList {
  list-style: none;
  width: 33%;
  max-width: 33%;
  box-sizing: border-box;
  padding: 10px;
  text-align: left;
}

.selectedCoopNumList {
  max-width: 100%;
  overflow: auto;
  width: 100%;
}

.selectedCoopNum {
  background-color: #d6d6d6;
  padding: 0px 11px;
  border-radius: 10px;
  margin: 1px;
}

.responsive-image {
  width: 100%;
  height: auto;
  max-width: 1400px;
  display: block;
  margin: 3% auto;
}
