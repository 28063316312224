/* login.css */
.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url("../assets/images/webBg.png");
    background-size: cover;
    background-position: center;
}

.loginBox {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    background: #00000075;
    height: 600px;
    justify-content: center;
    border-radius: 30px;
}

.loginLogo {
    width: 120px;
    height: 120px;
}

.textField {
    color: #fff !important;
    background-color: rgba(255, 240, 240, 0.2);
    border-radius: "10px";
}

.textFieldLabel {
    color: #fff !important;
    font-weight: bold;
    /* Input text color */
}

.textField .MuiInputBase-input {
    color: #fff !important;
    /* Sets the input text color to white */
}

.textField .MuiInput-underline:before {
    border-bottom-color: #233044 !important;
    /* Normal state border color */
}

.textField .MuiInput-underline:after {
    border-bottom-color: #233044 !important;
    /* Focused state border color */
}

.textField .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #233044 !important;
    /* Normal state border color */
}

.textField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #233044 !important;
    /* Hover state border color */
}

.textField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #233044 !important;
    /* Focused state border color */
}

.forgotPassword {
    text-align: right;
    color: white;
    margin: 10px 0 !important;
}

.loginButton {
    text-transform: none !important; 
    margin-top: 24px;
    margin-bottom: 16px;
    background-color:  #1a1f2e !important;
}

.loginButton:hover {
    background-color: #0e1937 !important;
}