@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");
.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Prompt", sans-serif;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: #fff;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #0e1937;
  color: white;
  font-weight: 600;

  border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

td {
  border-bottom: 1px solid #ddd;
  color: #000000;
}
thead {
  background-color: #0e1937;
  color: white;
}

.text{
  color: black;
}

.deleteButton {
  /* background-color: red !important; */
  color: rgb(0, 0, 0) !important;
  margin-left: 10px;
}

.deleteButton .MuiSvgIcon-root {
  color: rgb(21, 0, 128) !important;
}

.footer-btn {
  color: #0e1937 !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.footer-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

