@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap");

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Prompt", sans-serif;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  background-color: #fff;
}

.table td {
  color: black;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #0e1937;
  color: white;
  font-weight: 600;

  border-bottom: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

td {
  border-bottom: 1px solid #ddd;
  color: #666;
}

thead {
  background-color: #0e1937;
  color: white;
}

.pagination {
  float: right;
}

.download-btn {
  /* color: #0e1937 !important; */
  border: solid !important;
  margin-right: 5px !important;
}

.footer-btn {
  color: #0e1937 !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.footer-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}